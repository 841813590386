import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { breakpoints } from "../utils/breakpoints"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const MobileNav = () => {
  const [menuOpen, toggleMenuOpen] = useState(false)
  const data = useStaticQuery(graphql`
    {
      file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "kathleen_hsu_logo.png" }
      ) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <MenuBar>
      <Link to="/">
        <Img
          fixed={data.file.childImageSharp.fixed}
          alt="Logo"
          className="kathleen_hsu_logo"
        />
      </Link>
      <MenuIconContainer>
        <MenuIcon menuOpen={menuOpen} onClick={() => toggleMenuOpen(!menuOpen)}>
          <div />
          <div />
          <div />
        </MenuIcon>
      </MenuIconContainer>
      <MenuLinks menuOpen={menuOpen} onClick={() => toggleMenuOpen(!menuOpen)}>
        <div>
          <Link to="/" activeClassName="active">Work</Link>
        </div>
        <div>
          <Link to="/about" activeClassName="active">About</Link>
        </div>
      </MenuLinks>
    </MenuBar>
  )
}

export default MobileNav

const MenuBar = styled.header`
  @media ${breakpoints.lg} {
    display: none;
  }
  padding-left: 16px;
  padding-top: 20px;
  position: absolute;
  width: 100%;
  height: 4rem;
  background-image: linear-gradient("#f4ead8", "#e5f4f8");
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MenuIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const MenuIcon = styled.button`
  padding-right: 16px;
  cursor: pointer;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 2.1rem;
  z-index: 11;
  div {
    width: 2rem;
    height: 0.2rem;
    background: ${({ menuOpen }) => (menuOpen ? "#381115" : "#381115")};
    transform-origin: 1px;
    transition: opacity 300ms, transform 300ms;
    :first-child {
      transform: ${({ menuOpen }) =>
        menuOpen ? "rotate(45deg)" : "rotate(0)"};
    }
    :nth-child(2) {
      opacity: ${({ menuOpen }) => (menuOpen ? "0" : "1")};
      transform: ${({ menuOpen }) =>
        menuOpen ? "translateX(-20px)" : "translateX(0)"};
    }
    :nth-child(3) {
      transform: ${({ menuOpen }) =>
        menuOpen ? "rotate(-45deg)" : "rotate(0)"};
    }
  }
`

const MenuLinks = styled.nav`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  line-height: 1;
  background: #f9f8f4;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: transform 300ms;
  transform: ${({ menuOpen }) =>
    menuOpen ? "translateX(0)" : "translateX(100%)"};
  a {
    text-decoration: none;
    font-size: 1.325rem;
    font-weight: bold;
    font-family: "Inconsolata", sans-serif;
    color: #381115;
    display: block;
    padding: 1rem;
  }
`
