import React from "react"
import Header from "./header"
import AnimatedBg from "react-animated-bg"

function Wrapper(props) {
  return (
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 800, 
        padding: `0 1.0875rem 1.45rem`,
        minHeight: `100vh`
    }}
    >
      {props.children}
    </div>
  )
}

function Layout(props) {
  return (
    <AnimatedBg
      colors={["#fff9f2", "#eff2f4"]}
      duration={8}
      delay={2}
      timingFunction="linear"
    >
      <Header siteTitle="Kathleen Hsu" />
      <Wrapper>
        <main>{props.children}</main>
      </Wrapper>
    </AnimatedBg>
  )
}

export default Layout
