import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { breakpoints } from "../utils/breakpoints"

const DesktopNavMenu = () => {
  const [background, setBackground] = useState(false)
  const navRef = useRef()
  const data = useStaticQuery(graphql`
    {
      file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "kathleen_hsu_logo.png" }
      ) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  navRef.current = background
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 20
      if (navRef.current !== show) {
        setBackground(show)
      }
    }
    document.addEventListener("scroll", handleScroll)

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Header>
      <div className="container">
        <div className="inner-header">
          <div className="logo">
            <Link to="/">
              <Img
                fixed={data.file.childImageSharp.fixed}
                alt="Logo"
                className="kathleen_hsu_logo"
              />
            </Link>
          </div>
          <div className="navigation">
            <nav>
              <Link to="/" activeClassName="active">
                Work
              </Link>
              <Link to="/about" activeClassName="active">
                About
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </Header>
  )
}

export default DesktopNavMenu

const Header = styled.header`
  display: none;
  @media ${breakpoints.lg} {
    display: flex;
    height: 60px;
    width: 100%;
    position: absolute;
    top: 20px;
    z-index: 10;
    .inner-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      .navigation {
        nav {
          a {
            font-family: "Inconsolata", sans-serif;
            margin: 0 12px;
            text-decoration: none;
            font-size: 1.125rem;
            font-weight: normal;
            color: #381115;
            &:hover {
              color: #9d5900;
            }
            &:last-child {
              margin-right: 0px;
            }
            &.active {
              color: #381115;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`
